import { ThemeType } from '../../../../shared/enums';
import DarkHeaderLogo from '../images/logo.webp';
import HeaderLogo from '../images/logo.webp';

const VectorLogo = undefined;

const defaultTheme = ThemeType.LIGHT;
const hasThemeSwitcher = false;
const hasNetworkSwitcher = true;
const hasWhatsappWidget = false;
const hasBanner = true;
const isDisableWalletLogin = false;

const headerLogoSize = 144;
const FooterLogo = HeaderLogo;
const DarkFooterLogo = DarkHeaderLogo;
const footerLogoSize = 144;

export {
  VectorLogo,
  HeaderLogo,
  DarkHeaderLogo,
  headerLogoSize,
  FooterLogo,
  DarkFooterLogo,
  footerLogoSize,
  defaultTheme,
  hasThemeSwitcher,
  hasNetworkSwitcher,
  hasWhatsappWidget,
  hasBanner,
  isDisableWalletLogin,
};
